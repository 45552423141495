import { ResourceUrlEnum } from '@/resource/enum';
import { MinIoResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Client, ClientOptions, ItemBucketMetadata, UploadedObjectInfo } from 'minio';
import { Readable } from 'stream';
// eslint-disable-next-line @typescript-eslint/no-duplicate-imports
import * as Minio from 'minio';
import { translation } from '@/utils';
export interface MinIOFile {
  name: string;
  stream: Readable | Buffer | string;
  size?: number;
  metadata?: ItemBucketMetadata;
  bucketName?: string;
  /**
   * 文件上传到minio的完整路径
   */
  path?: string;
  /**
   * 原始文件对象
   */
  originFile?: File;
}

class MinIOService {
  public get endPoint(): string {
    return this.minIoOptions.endPoint;
  }

  private minIoOptions!: ClientOptions;

  /**
   * 桶的名字
   */
  private bucketName!: string;

  private _minioClient!: Client;
  /**
   * minio服务实例
   */
  private get minioClient(): Client {
    if (!this._minioClient) {
      console.error('minio服务尚未初始化');
      throw new Error(translation('minio.initError'));
    }
    return this._minioClient;
  }
  private set minioClient(client: Client) {
    this._minioClient = client;
  }

  /**
   * 通过流上传文件到minio
   * @param file 文件对象
   * @param savePath 桶内的文件路径
   * @returns
   */
  public async uploadByStream(file: MinIOFile, savePath: string): Promise<UploadedObjectInfo> {
    try {
      if (!file.bucketName) {
        file.bucketName = this.bucketName;
      }
      const isExist = await this.minioClient.bucketExists(file.bucketName);
      if (!isExist) {
        throw new Error('上传容器地址不存在');
      }
      const uploadRes = await this.minioClient.putObject(
        file.bucketName,
        `${savePath}/${file.name}`,
        file.stream,
        file.size,
        file.metadata
      );
      return Promise.resolve(uploadRes);
    } catch (error) {
      if (typeof error === 'string') {
        return Promise.reject(error);
      }
      return Promise.reject(JSON.stringify(error));
    }
  }

  /**
   * 从minio获取可以用于下载的文件路径
   * @param fileName 文件名
   * @param bucketName 桶名，默认为获取配置信息中的桶
   * @param expiry 失效时间（单位为秒）不填默认为10分钟
   * @returns
   */
  public async getDownLoadPath(
    fileName: string,
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    bucketName: string = this.bucketName,
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    expiry: number | undefined = 600
  ): Promise<string> {
    try {
      const isExist = await this.minioClient.bucketExists(bucketName);
      if (!isExist) {
        console.error('容器不存在');
        throw new Error(translation('minio.bucketNotExist'));
      }
      const url = this.minioClient.presignedUrl('GET', bucketName, fileName, expiry);
      return Promise.resolve(url);
    } catch (error) {
      return Promise.reject(typeof error === 'string' ? error : error.message);
    }
  }

  /**
   * 初始化minio服务
   */
  public init(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.minIoOptions) {
        resolve();
        return;
      }
      axiosService
        .get<MinIoResource>(`${ResourceUrlEnum.minio}/getMinIoInfo`, {
          params: { minIoName: process.env.VUE_APP_MINIO_NAME }
        })
        .then((res: ApiResponse<MinIoResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          this.minIoOptions = {
            endPoint: res.data.endpoint,
            accessKey: res.data.accessKey,
            secretKey: res.data.secretKey,
            useSSL: true
          };
          this.bucketName = res.data.bucket;
          this.minioClient = new Minio.Client(this.minIoOptions);
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const minioService = new MinIOService();
export default minioService;
