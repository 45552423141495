import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { AxiosResponse } from 'axios';
import { BaseService, Paging, Query } from '../base';
import {
  BackendCraftResource,
  ClassifyResource,
  OutputCraftResource,
  PlatformProductResource,
  ProductResource,
  ResourceBase
} from '@/resource/model';
import { axiosRequest } from '@/utils/axios-request';
import { handleDownload } from '@/utils';
import { CascaderOption } from 'element-ui/types/cascader-panel';

class ProductService extends BaseService<ResourceBase> {
  public getById(id: number): Promise<ProductResource> {
    if (!id) {
      throw new Error('id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .get<ProductResource>(`${ResourceUrlEnum.product}`, { params: { id } })
        .then((res: ApiResponse<ProductResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 分页查询
   * @param query 查询条件
   * @param paging 分页参数
   * @returns
   */
  public getList(
    query: Query<ProductResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProductResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProductResource>>(`${ResourceUrlEnum.product}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<ProductResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 新增
   * @param resource 新增数据对象
   */
  public post(resource: ProductResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(ResourceUrlEnum.product, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编辑
   * @param resource 编辑数据对象
   */
  public put(resource: ProductResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(ResourceUrlEnum.product, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除
   * @param id ID
   */
  public delete(id: number): Promise<void> {
    if (!id) {
      throw new Error('id must not be null');
    }
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.product, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除
   * @param idList ID集合
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    if (!idList || idList.length <= 0) {
      throw new Error('idList must not be null or empty');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.product}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载导入模板
   */
  public downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.product}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 数据导入
   * @param filePath 导入文件地址
   */
  public import(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.product}/importData`, { filePath })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            return reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 数据导出
   */
  public export(query: Query<any>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.product}/export`, query, { responseType: 'blob' })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修改状态
   * @param idList ID集合
   * @param status 状态
   */
  public batchUpdateStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    if (!idList || idList.length <= 0) {
      throw new Error('idList must not be null or empty');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.product}/editStatus`, { idList, status })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有可用分类数据
   * @returns 可用分类
   */
  public getAllUsingClassify(): Promise<Array<ClassifyResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<ClassifyResource>>(`${ResourceUrlEnum.productClassify}/listEnable`)
        .then((res: ApiResponse<Array<ClassifyResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取物料类别树
   */
  public getTreeData(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<any>>(`/product/material/category/listForTreeComponents`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取平台端所有启用的产品
   * @returns 平台端可用的产品
   */
  public getAllUsingPlatformProduct(
    query?: Query<PlatformProductResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<PlatformProductResource>>> {
    return new Promise((resolve, reject) => {
      const cloneQuery = {};
      if (query && Object.keys(query).length > 0) {
        Object.assign(cloneQuery, query);
      }
      if (paging && Object.keys(paging).length > 0) {
        Object.assign(cloneQuery, paging);
      }
      axiosService
        .post<any>('platform/product/listEnable', cloneQuery)
        .then((res: ApiResponse<Array<PlatformProductResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询所有可用的后道工艺
   */
  public getAllUsingBackendCraft(): Promise<Array<BackendCraftResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<BackendCraftResource>>('system/crafts/backend/list', {})
        .then((res: ApiResponse<Array<BackendCraftResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询所有可用的输出工艺
   */
  public getAllUsingOutputCraft(): Promise<Array<OutputCraftResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<OutputCraftResource>>('/system/crafts/printing/list', {})
        .then((res: ApiResponse<Array<OutputCraftResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 转换原始分类数据结构为级联数据结构
   * @param list 多级分类数据
   * @returns
   */
  public handleCascaderOption(
    list: Array<{
      id: number;
      categoryName: string;
      level: number;
      children: Array<any> | undefined;
      valueList: Array<number>;
    }>
  ): Array<CascaderOption> {
    return list.map(x => {
      const option: CascaderOption = {
        label: x.categoryName,
        value: x.id
      };
      if (x.children && x.children.length > 0) {
        option.children = this.handleCascaderOption(x.children);
      }
      return option;
    });
  }
}

const productService = new ProductService();
export default productService;
