/**
 * 印前排版工艺
 */
enum PrepressLayoutCraftsEnum {
  /**
   * 原档输出
   */
  noneAdjustmentToPrint = 1,
  /**
   * 按guideline排版
   */
  accordingToGuideline = 3,
  /**
   * 调整尺寸&客户指定内容
   */
  dimensionAdjustment = 2,
  /**
   * 依美观排版
   */
  accordingPretty = 18,
  /**
   * 比例档
   */
  ratioLayout = 4,
  /**
   * 依比例档排版
   */
  accordingPrtion = 12,
  /**
   * 围档排版
   */
  fence = 13,
  /**
   * 吊旗排版
   */ showbill = 14,
  /**
   * 线稿排版
   */
  lineDraft = 15,
  /**
   * 调打
   */
  copyPrint = 16,
  /**
   * 外发文件
   */
  printReadyFile = 5,
  /**
   * 像素排版
   */
  pixelLayout = 6,
  /**
   * 其他
   */
  others = 11,
}

/**
 * 印前改色工艺
 */
enum PrepressChangeColorCraftsEnum {
  /**
   * 原档输出
   */
  noneAdjustmentToPrint = 1,
  /**
   *  依客户样对色
   */
  accordingToTheProof = 7,
  /**
   * 依指定色票对色
   */
  accordingColorCard = 17,
  /**
   * 依屏幕色改色
   */
  softPrinting = 10,
  /**
   * 颜色漂亮美观
   */
  commonSense = 9,
  /**
   * 修图补图
   */
  graphicRetouch = 8,
  /**
   * 其他
   */
  others = 11,
}

export { PrepressLayoutCraftsEnum, PrepressChangeColorCraftsEnum };
