import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { ImportRes, PersonnelResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging, Query } from '../base';

class PersonnelService extends BaseService<PersonnelResource> {
  public async delete(id: number): Promise<void> {
    await this.batchDelete([id]);
  }
  public getById(id: number): Promise<PersonnelResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<PersonnelResource>(ResourceUrlEnum.personnel, { params: { id } })
        .then((res: ApiResponse<PersonnelResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: PersonnelResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<PersonnelResource>(ResourceUrlEnum.personnel, resource)
        .then((res: ApiResponse<PersonnelResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: PersonnelResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<PersonnelResource>(ResourceUrlEnum.personnel, resource)
        .then((res: ApiResponse<PersonnelResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(list: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.personnel}/batchDelete`, { data: list })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Query<PersonnelResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<PersonnelResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<PersonnelResource>>(`${ResourceUrlEnum.personnel}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<PersonnelResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有启用状态的人员
   * @returns
   */
  public getAllPersonnels(): Promise<Array<PersonnelResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<PersonnelResource>>(`${ResourceUrlEnum.personnel}/list`)
        .then((res: ApiResponse<Array<PersonnelResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查人员属性是否重复
   * @param props 属性对象 { userName?: string; email?: string; mobile?: string },
   * @param id 人员主键 （修改时需要传入）
   * @returns 为true则代表重复
   */
  public checkPersonnelProp(
    props: { userName?: string; email?: string; mobile?: string },
    id?: number
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<boolean>(`${ResourceUrlEnum.personnel}/checkData`, { id, ...props })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修改人员状态
   * @param idList 人员id数组
   * @param status 状态
   * @returns
   */
  public batchUpdatePersonnelStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.personnel}/editStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 重置用户密码
   * @param id 用户id
   * @returns
   */
  public resetPassword(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(
          `${ResourceUrlEnum.personnel}/resetPassword`,
          {},
          {
            params: { id }
          }
        )
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public importPersonnel(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.personnel}/importData`, { filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取部门下所有人员
   * @param depId 部门Id
   * @returns
   */
  public getPersonnelsByDepId(depId: number): Promise<Array<PersonnelResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<PersonnelResource>>(`${ResourceUrlEnum.personnel}/listByDepId`, { params: { depId } })
        .then((res: ApiResponse<Array<PersonnelResource>>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const personnelService = new PersonnelService();
export default personnelService;
