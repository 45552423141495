import { ApiError } from '@/api/axios';
import i18n from '@/lang';
import { ErrorLevelEnum } from '@/resource/enum';
import { BatchOperationError } from '@/resource/model/common';

const prefixName = 'requestError';
const constantError: { [prop: number]: { level: ErrorLevelEnum; i18nKey: string } } = {
  // 公共异常
  '-4': { level: ErrorLevelEnum.error, i18nKey: '_4' },
  10001: { level: ErrorLevelEnum.error, i18nKey: '_10001' },
  10002: { level: ErrorLevelEnum.error, i18nKey: '_10002' },
  10003: { level: ErrorLevelEnum.error, i18nKey: '_10003' },
  10004: { level: ErrorLevelEnum.error, i18nKey: '_10004' },
  10005: { level: ErrorLevelEnum.error, i18nKey: '_10005' },
  10006: { level: ErrorLevelEnum.error, i18nKey: '_10006' },
  10007: { level: ErrorLevelEnum.error, i18nKey: '_10007' },
  10008: { level: ErrorLevelEnum.error, i18nKey: '_10008' },
  10009: { level: ErrorLevelEnum.error, i18nKey: '_10009' },
  10010: { level: ErrorLevelEnum.error, i18nKey: '_10010' },
  10011: { level: ErrorLevelEnum.error, i18nKey: '_10011' },
  10012: { level: ErrorLevelEnum.error, i18nKey: '_10012' },
  10013: { level: ErrorLevelEnum.error, i18nKey: '_10013' },
  10014: { level: ErrorLevelEnum.error, i18nKey: '_10014' },
  10015: { level: ErrorLevelEnum.error, i18nKey: '_10015' },
  10016: { level: ErrorLevelEnum.error, i18nKey: '_10016' },
  10017: { level: ErrorLevelEnum.error, i18nKey: '_10017' },
  10018: { level: ErrorLevelEnum.error, i18nKey: '_10018' },
  10019: { level: ErrorLevelEnum.error, i18nKey: '_10019' },
  10020: { level: ErrorLevelEnum.error, i18nKey: '_10020' },
  10021: { level: ErrorLevelEnum.error, i18nKey: '_10021' },
  10022: { level: ErrorLevelEnum.error, i18nKey: '_10022' },
  10023: { level: ErrorLevelEnum.error, i18nKey: '_10023' },
  42301: { level: ErrorLevelEnum.warning, i18nKey: '_42301' },

  // 登录模块
  20101: { level: ErrorLevelEnum.warning, i18nKey: '_20101' },
  20102: { level: ErrorLevelEnum.warning, i18nKey: '_20102' },
  20103: { level: ErrorLevelEnum.warning, i18nKey: '_20103' },
  20104: { level: ErrorLevelEnum.warning, i18nKey: '_20104' },
  20105: { level: ErrorLevelEnum.warning, i18nKey: '_20105' },
  20106: { level: ErrorLevelEnum.warning, i18nKey: '_20106' },
  20107: { level: ErrorLevelEnum.warning, i18nKey: '_20107' },
  20108: { level: ErrorLevelEnum.warning, i18nKey: '_20108' },
  20109: { level: ErrorLevelEnum.warning, i18nKey: '_20109' },
  20110: { level: ErrorLevelEnum.warning, i18nKey: '_20110' },
  20111: { level: ErrorLevelEnum.warning, i18nKey: '_20111' },
  20112: { level: ErrorLevelEnum.warning, i18nKey: '_20112' },
  20113: { level: ErrorLevelEnum.warning, i18nKey: '_20113' },
  20114: { level: ErrorLevelEnum.warning, i18nKey: '_20114' },
  20115: { level: ErrorLevelEnum.warning, i18nKey: '_20115' },
  20116: { level: ErrorLevelEnum.warning, i18nKey: '_20116' },
  // 用户模块
  20201: { level: ErrorLevelEnum.warning, i18nKey: '_20201' },
  20202: { level: ErrorLevelEnum.warning, i18nKey: '_20202' },
  20203: { level: ErrorLevelEnum.warning, i18nKey: '_20203' },
  20204: { level: ErrorLevelEnum.warning, i18nKey: '_20204' },
  20205: { level: ErrorLevelEnum.warning, i18nKey: '_20205' },
  20206: { level: ErrorLevelEnum.warning, i18nKey: '_20206' },
  20207: { level: ErrorLevelEnum.warning, i18nKey: '_20207' },

  // 资源模块
  20401: { level: ErrorLevelEnum.error, i18nKey: 'rsourceNameRepeat' },
  20406: { level: ErrorLevelEnum.error, i18nKey: 'resourceParentNull' },
  20404: { level: ErrorLevelEnum.error, i18nKey: 'hasChildResource' },
  20405: { level: ErrorLevelEnum.error, i18nKey: 'deleteResourceError' },

  // 岗位管理
  20502: { level: ErrorLevelEnum.warning, i18nKey: '_20502' },
  20503: { level: ErrorLevelEnum.warning, i18nKey: '_20503' },
  // 部门管理
  20601: { level: ErrorLevelEnum.warning, i18nKey: '_20601' },
  20602: { level: ErrorLevelEnum.warning, i18nKey: '_20602' },
  20603: { level: ErrorLevelEnum.warning, i18nKey: '_20603' },
  20604: { level: ErrorLevelEnum.warning, i18nKey: '_20604' },

  // 角色管理
  21801: { level: ErrorLevelEnum.warning, i18nKey: '_21801' },
  21802: { level: ErrorLevelEnum.warning, i18nKey: '_21802' },

  // 人员管理
  20209: { level: ErrorLevelEnum.error, i18nKey: 'userNameRepeat' },
  20210: { level: ErrorLevelEnum.error, i18nKey: 'emailRepeat' },
  20211: { level: ErrorLevelEnum.error, i18nKey: 'mobileRepeat' },

  // 供应商产品
  22301: { level: ErrorLevelEnum.error, i18nKey: 'printingMaterialCanNotUse' },
  22302: { level: ErrorLevelEnum.error, i18nKey: 'printingCraftCanNotUse' },
  22303: { level: ErrorLevelEnum.error, i18nKey: 'backendMaterialCanNotUse' },
  22304: { level: ErrorLevelEnum.error, i18nKey: 'backendCraftCanNotUse' },

  22813: { level: ErrorLevelEnum.warning, i18nKey: '_22813' },

  // 发货通知单
  22908: { level: ErrorLevelEnum.error, i18nKey: 'unDelivery' },
  22914: { level: ErrorLevelEnum.error, i18nKey: '_22914' },
  22917: { level: ErrorLevelEnum.error, i18nKey: 'hasLogisticOrderCanNotCancel' },
  22918: { level: ErrorLevelEnum.error, i18nKey: 'notCancelFinish' },

  // 物流单
  23101: { level: ErrorLevelEnum.error, i18nKey: 'receivingStatusUnMatched' },
  23102: { level: ErrorLevelEnum.error, i18nKey: 'orderNotReadyForDelivery' },
  23108: { level: ErrorLevelEnum.error, i18nKey: 'logisticsOrderSender' },
  23109: { level: ErrorLevelEnum.error, i18nKey: 'logisticsOrderSenderTel' },
  23115: { level: ErrorLevelEnum.error, i18nKey: 'logisticsOrderNo' },
  23116: { level: ErrorLevelEnum.error, i18nKey: 'logisticsOrderExpressName' },
  23127: { level: ErrorLevelEnum.error, i18nKey: 'orderNotclose' },

  // 单据管理
  22805: { level: ErrorLevelEnum.error, i18nKey: 'orderItemTakeError' },
  22806: { level: ErrorLevelEnum.error, i18nKey: 'orderItemFinishError' },
  22810: { level: ErrorLevelEnum.error, i18nKey: 'canNotReturnNotOpenProcess' },
  22811: { level: ErrorLevelEnum.error, i18nKey: 'prepressUnfinished' },
  22814: { level: ErrorLevelEnum.error, i18nKey: 'loyoutNotFound' },
  22817: { level: ErrorLevelEnum.error, i18nKey: 'platformgPrepressNotOpen' },
  22818: { level: ErrorLevelEnum.error, i18nKey: 'orderItemExistChangeOrder' },

  // 生产订单
  22701: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderExist' },
  22702: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderNotExist' },
  22704: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderStatusNotNew' },
  22705: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderStatusNotWaitConfirm' },
  22706: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderStatusNotWaitAccept' },
  22707: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderStatusNotWaitSchedule' },
  22708: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderStatusNotInProgress' },
  22709: { level: ErrorLevelEnum.warning, i18nKey: 'productCountOver' },
  22710: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderDetailNotExist' },

  // 生产订单变更单
  23001: { level: ErrorLevelEnum.warning, i18nKey: 'changeLogCanNotPush' },
  23002: { level: ErrorLevelEnum.warning, i18nKey: 'changeLogCanNotConfirm' },
  23003: { level: ErrorLevelEnum.warning, i18nKey: 'existChangeLog' },
  23004: { level: ErrorLevelEnum.warning, i18nKey: 'changeLogNotExist' },
  23005: { level: ErrorLevelEnum.warning, i18nKey: 'changeProductNotExist' },
  23006: { level: ErrorLevelEnum.warning, i18nKey: 'supplierProductNotExist' },
  23007: { level: ErrorLevelEnum.warning, i18nKey: 'canNotChangeComplatedOrder' },
  23008: { level: ErrorLevelEnum.warning, i18nKey: 'changeLogNoDetail' },
  23010: { level: ErrorLevelEnum.warning, i18nKey: 'sentDownItem' },
  22802: { level: ErrorLevelEnum.warning, i18nKey: 'billInProduction' },
  // 反馈建议
  24601: { level: ErrorLevelEnum.warning, i18nKey: '_24601' },
  24602: { level: ErrorLevelEnum.warning, i18nKey: '_24602' },
  24603: { level: ErrorLevelEnum.warning, i18nKey: '_24603' }
};

/**
 * 获取api响应结果中的异常信息
 * @param code 异常码
 * @returns 经由国际化处理后的异常信息
 */
export const getErrorObj = (code: number): ApiError => {
  const key = `${prefixName}.${constantError[code]?.i18nKey || 'unknownError'}`;
  return {
    code: code,
    level: constantError[code]?.level || ErrorLevelEnum.error,
    message: i18n.t(key) as string
  };
};

const httpPrefixName = 'httpRequestError';
const constantHttpError: { [prop: number]: { level: ErrorLevelEnum; i18nKey: string } } = {
  400: { level: ErrorLevelEnum.error, i18nKey: 'requestError' },
  401: { level: ErrorLevelEnum.error, i18nKey: 'unAuthorized' },
  403: { level: ErrorLevelEnum.error, i18nKey: 'serverDeniedAccess' },
  404: { level: ErrorLevelEnum.error, i18nKey: 'resourceNotFound' },
  405: { level: ErrorLevelEnum.error, i18nKey: 'methodDisabled' },
  408: { level: ErrorLevelEnum.error, i18nKey: 'timeout' },
  500: { level: ErrorLevelEnum.error, i18nKey: 'serverError' },
  501: { level: ErrorLevelEnum.error, i18nKey: 'serverNotSupport' },
  502: { level: ErrorLevelEnum.error, i18nKey: 'networkError' },
  503: { level: ErrorLevelEnum.error, i18nKey: 'serveDied' },
  504: { level: ErrorLevelEnum.error, i18nKey: 'neworkTimeout' },
  505: { level: ErrorLevelEnum.error, i18nKey: 'httpVersionNotSupport' },
  5001: { level: ErrorLevelEnum.info, i18nKey: 'httpCanceled' },
  5002: { level: ErrorLevelEnum.warning, i18nKey: 'oftenRequest' }
};

/**
 * 根据http响应异常状态码，返回异常信息
 * @param code 异常状态码
 * @returns 经由国际化处理后的异常信息
 */
export const getHttpErrorObj = (code?: number): ApiError => {
  if (!code) {
    return {
      level: ErrorLevelEnum.error,
      message: i18n.t(`${httpPrefixName}.otherError`) as string
    };
  }
  const key = `${httpPrefixName}.${constantHttpError[code]?.i18nKey || 'otherError'}`;
  return {
    code: code,
    level: constantHttpError[code]?.level || ErrorLevelEnum.error,
    message: i18n.t(key) as string
  };
};

export const getBatchOperationErrorObjs = (errors: Array<BatchOperationError>): Array<ApiError> => {
  if (!errors || errors.length === 0) {
    return [
      {
        level: ErrorLevelEnum.error,
        message: i18n.t(`${httpPrefixName}.otherError`) as string
      }
    ];
  }
  return errors.map(x => {
    const obj = getErrorObj(x.code);
    obj.message = `${x.key} : ${obj.message}`;
    return obj;
  });
};
