import { render, staticRenderFns } from "./side-bar-item.vue?vue&type=template&id=3085434a&scoped=true"
import script from "./side-bar-item.ts?vue&type=script&lang=ts&external"
export * from "./side-bar-item.ts?vue&type=script&lang=ts&external"
import style0 from "./side-bar-item.scoped.scss?vue&type=style&index=0&id=3085434a&prod&scoped=true&lang=scss&external"
import style1 from "./side-bar-item.scss?vue&type=style&index=1&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3085434a",
  null
  
)

export default component.exports